footer {
  width: 100%;
  margin-top: 20px;
}

footer .footer-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 80%;
  margin: auto;
  padding: 40px;
}

footer .footer-container .footer-col .footer-logo-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logo-flex{
  margin: 0 0 0 5rem;
  transform: scale(1.5);
}

.separator {
  width: 80%;
}

.footer-links h3 {
  margin: 0 0 1rem 0;
}

.footer-links li {
  margin: 0 0 0.5rem 0;
}

footer .footer-container .footer-col a {
  color: #935cfe;
}

footer .footer-container .footer-col img {
  width: 15%;
}

footer .footer-container .footer-col ul {
  list-style: none;
}

footer .footer-container .footer-col .footer-socials {
  display: flex;
}

footer .footer-container .footer-col .footer-socials li {
  margin-right: 10px;
}

footer .footer-container .footer-col .footer-socials li svg {
  font-size: 30px;
  color: #935cfe;
}

.copyright {
  text-align: center;
  padding: 10px;
  padding: 0 0 4rem 0;
}

@media screen and (max-width: 768px) {
  footer .footer-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 90%;
    margin: auto;
    padding: 30px;
  }
  footer .footer-container .footer-col img {
    width: 10%;
  }
  .footer-logo-flex {
    visibility: hidden;
  }
}
