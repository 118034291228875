header {
  width: 100%;
  position: relative;
  z-index: 0;
}

header .header-flex {
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

header .header-content-inner p {
  margin: 1rem 0 0 0;
}

header .header-content {
  width: 50%;
  margin-top: 8rem;
  margin-bottom: 2rem;
}

header .header-content h1 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -2px;
}

.subtext-head {
  font-family: "Poppins", sans-serif;

  font-size: 0.9rem;
}

header .header-content h1 .hero-content-line {
  height: 58px;
  overflow: hidden;
  font-family: "Azonix";
  /* margin-top: -1.3rem; */
}

.header-content p {
  color: var(--grey-color);
}

header .header-image {
  position: relative;
  width: 50%;
}

.header-image-inner video {
  height: 500px;
  width: 750px;
  overflow: hidden;
}

header .header-image img {
  width: 100%;
}

header .backed {
  text-align: center;
  margin-top: 10%;
}

header .backed .backed-flex {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.back-col {
  display: flex;
  flex-direction: column;
}

header .backed .backed-flex img {
  width: 10%;
}

@media screen and (max-width: 1200px) {
  header .header-content h1 {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 1024px) {
  header .header-flex {
    width: 80%;
  }

  header .header-content {
    margin-top: 50px;
  }
  header .header-content h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 900px) {
  header .header-flex {
    flex-direction: column;
  }
  header .header-content {
    width: 100%;
  }
  header .header-image {
    width: 90%;
    margin: auto;
  }
  header .backed {
    margin-top: 80%;
  }
}

@media screen and (max-width: 500px) {
  header .header-flex {
    width: 90%;
  }
  header .header-image-inner video {
    width: 300px;
    height: 300px;
    margin: 0;
  }
  header .header-content {
    margin-top: 30px;
  }
  header .backed .backed-flex img {
    width: 8%;
  }
  header .backed h1 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 430px) {
  header .header-content h1 {
    font-size: 2rem;
  }
  header .header-content h1 .hero-content-line {
    height: 68px;
    margin-top: -1rem;
  }
  header .backed {
    margin-top: 10%;

  }
}
