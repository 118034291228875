main {
  margin-top: 50px;
}
.features-grid {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.pricing-section hr {
  border: 0;
  clear:both;
  display:block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 60%;               
  background-color: rgba(128, 128, 128, 0.134);
  height: 1px;
}
.pricing-section {
  display: flex;
  justify-items: space-around;
  align-items: center;
}
.feature-image img {
  width: 90%;
}

.feature-grid-content {
  width: 80%;
  margin: auto;
}

.feature-grid-content h1,
.feature-grid-content p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.feature-grid-content p {
  color: rgba(0, 0, 0, 0.382);
  font-family: "Poppins", sans-serif;
  font-weight: 450;
  font-size: 1.1rem;
}
.feature-grid-content h1 {
  font-size: 2rem;
  font-weight: bold;
}

.feature-grid-content a {
  text-decoration: none;
  color: #935cfe;
}

.features-method {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}

.features-method h1,
.features-method p {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.features-method .methods-flex {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.features-method .methods-flex .method-image {
  width: 40%;
}

.features-method .methods-flex .method-image img {
  width: 100%;
}

.features-method .methods-flex .method-content {
  width: 38%;
  text-align: left;
  margin-top: 80px;
}

.features-method .methods-flex .method-content .method-content-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
  cursor: pointer;
}

.features-method .methods-flex .method-content a {
  color: var(--black-color);
}

.features-method .methods-flex .method-content .method-content-box p {
  margin-left: 10px;
}

.features-method .methods-flex .method-content .method-content-box svg {
  padding: 10px;
  border-radius: 50%;
  background-color: #935cfe;
  color: var(--white-color);
}

.features-method .methods-flex .method-content .method-content-box .f-svg {
  width: 8%;
  height: 5%;
}

.features-method .methods-flex .method-content .method-content-box .f-icon {
  width: 4%;
  height: 3%;
}

.pricing {
  width: 90%;
  margin: 0 auto;
}

.pricing .pricing-box {
  width: 30%;
  margin: auto;
  box-shadow: rgba(149, 157, 165, 0.25) 0px 1px 24px;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 80px;
  border-radius: 20px;
}
 /* ul{
  
  list-style:disc;
  list-style-position: outside;

}
li{
  display: list-item;
} */
.pricing .pricing-box h1 {
  text-align: center;
  margin: 0px;
  padding: 0px;
  font-size: larger;
  font-weight: normal;
}
#special-price{
  font-size: 2.2rem;
  font-weight: 500;
  background-image: linear-gradient(135deg, #6699ff 0%, #ff3366 100%);
  font-family: "Poppins", sans-serif;
  background-clip: text;
  color: transparent;
}
.pricing h1 {
  margin-top: 5%;
  font-size: x-large;
  font-weight: bold;
}
.hear-com {
  display: flex;
  justify-content: center;
  font-size: xx-large;
  font-weight: 400;
  font-style: normal;
  color: #52585d;
  margin-top: 10%;
}
.price-flex .on-each-ticket {
  font-size: x-small;
  font-weight: 400;
  font-style: normal;
  color: #52585d;
}
.pricing .pricing-box .price-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0.5rem;
}

.price-flex {
  font-weight: 500;
}

.pricing .pricing-box ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  width: 80%;
  margin: 50px auto;
  font-weight: 400;
  font-size: 1rem;
}

/* .pricing .pricing-box ul li {
  margin-bottom: 10px;
  font-size:medium;
  font-weight: 400;
} */

.pricing .pricing-box button {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.community {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: var(--white-color);
  background: linear-gradient(135deg, #6699ff 0%, #ff3366 100%);
  padding: 50px;
  border-radius: 10px;
  margin-top: 20px;
}

.community .socials {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 20px;
}

.community .socials li {
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .features-method .methods-flex .method-content {
    margin-top: 40px;
    width: 50%;
  }
  .pricing .pricing-box {
    width: 60%;
  }
  .pricing .pricing-box button {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  main {
    margin-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  .features-method .methods-flex .method-content {
    width: 90%;
    margin-top: 10px;
  }
  .features-method .methods-flex {
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  .features-method .methods-flex .method-image {
    width: 80%;
  }
  .pricing .pricing-box button {
    width: 100%;
  }
  .pricing-section {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .features-grid {
    width: 90%;
    margin: auto;
  }
  .pricing .pricing-box {
    width: 90%;
  }
  .community {
    width: 90%;
    padding: 20px;
  }
  .community h1 {
    font-size: 20px;
  }
  .features-method .methods-flex .method-content .method-content-box .f-svg {
    width: 12%;
    height: 5%;
  }

  .features-method .methods-flex .method-content .method-content-box .f-icon {
    width: 5%;
    height: 3%;
  }
}

@media screen and (max-width: 390px) {
  .features-method .methods-flex .method-content .method-content-box .f-svg {
    width: 15%;
    height: 5%;
  }
  .features-method .methods-flex .method-content .method-content-box .f-icon {
    width: 6%;
    height: 4%;
  }
}
